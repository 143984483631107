<template>
  <div>
    <div
      v-for="(option, index) in options"
      @click="option.is_checked = !option.is_checked"
      class="mb-2 whitespace-nowrap flex items-center text-gray-600 cursor-pointer"
      :key="'options' + index"
      style="word"
    >
      <input
        :checked="option['is_checked']"
        type="checkbox"
        class="mr-2 form-checkbox cursor-pointer text-oDark border-oDark transition duration-150 ease-in-out focus:shadow-none text-oDark border-oDark focus:border-oDark"
      />
      <span class="whitespace-nowrap" style="white-space: nowrap;">{{
        option[itemLabel]
      }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MultiSelectList',
  props: {
    options: {
      type: Array,
      required: true,
    },
    itemLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCheckedModel: {},
    }
  },
  watch: {
    options: {
      handler() {
        let checkedItem = this.options.filter((item) => {
          return (item = item.is_checked === true)
        })
        this.isCheckedModel = checkedItem
        this.$emit('change', this.isCheckedModel)
      },
      deep: true,
    },
  },
  methods: {},
}
</script>
